
const anker = document.querySelectorAll('a[href^="#"]');
const bodyHeight = document.body.clientHeight;
  // ↓document.documentElement.clientHeight +以降の記述はスクロールの高さを取得するための記述であるが、クローム、IEなどの各ブラウザに対応するために記述を分けている
const windowScrollHeight = document.documentElement.clientHeight + document.documentElement.scrollTop || document.body.scrollTop || document.scrollingElement.scrollTop || window.pageYOffset || window.scrollY;
const headerHeight = document.querySelector('.header').clientHeight;

for (let i = 0; i < anker.length; i++){
  anker[i].addEventListener('click', (e) => {
    e.preventDefault();
    let href = anker[i].getAttribute('href');
    let targetElement = document.getElementById(href.replace('#', ''));
    const rect = targetElement.getBoundingClientRect().top;
    const offset = window.pageYOffset;
    const gap = headerHeight + 20;
    const target = rect + offset - gap;
    window.scrollTo({
      top: target,
      behavior: 'smooth',
    });
  });
};
